import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../../../../assets/config/config.js';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  private endPoint;
  private headers;
  constructor( private http: HttpClient) {
    this.endPoint = config.url + '/blog';
    this.headers = new HttpHeaders();
    this.headers.append('Content-Type' , 'application/json');
    this.headers.append('Accept' , 'application/json');
  }

  getcomentspost(post: number): Observable<any> {
    return this.http.get(this.endPoint + '/posts/' + post + '/comments?liberado=1',this.headers)
  }
  insertcomments(comment: any): Observable<any> {
    return this.http.post(this.endPoint + '/comments', comment, this.headers);
  }
}
