import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import{ config } from '../../../assets/config/config.js';

@Injectable({
    providedIn: 'root'
})
export class PostsService {

  url: string;
  constructor(private http: HttpClient) {
    this.url = config.url + '/blog/posts';
    //this.url = "../../assets/mook.json"
  }

    getpost(parametros?:any): Observable<any>{
       return this.http.get(this.url);
    }
}


