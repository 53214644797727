import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {config} from './../assets/config/config.js';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImagemserviceService {

  private url;
  private options:HttpHeaders
  constructor(private http:HttpClient) {
    this.url = config.url + "/blog/imagens";

   this.options = new HttpHeaders();
   this.options.set("Access-Control-Allow-Origin","*");
   }

   getAllImages():Observable<any>{
    return this.http.get(this.url);
   }

   uploadimage(image){
     return this.http.post(this.url,image,{headers: this.options});
   }
}
