import { Component, OnInit,Renderer2,ElementRef, ViewChild, forwardRef, Output,EventEmitter,Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FerramentasComponent } from '../ferramentas/ferramentas.component';

@Component({
  selector: 'text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.css'],
  providers:[{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextEditorComponent),
    multi:true
  }],
})
export class TextEditorComponent implements ControlValueAccessor{
  @ViewChild (FerramentasComponent) ferramentas : FerramentasComponent;
  @ViewChild('textarea') textarea:ElementRef<HTMLTextAreaElement>;
  private value :string = "";
  onChange: any = () => {};
  onTouch: any = ()=>{};
  @Input() disable = false;
  @Output() alterou = new EventEmitter();

  constructor() { }

  set val (val){
    this.value = val;
    this.onChange(val);
    this.onTouch(val);
  }
  
  writeValue(value: any): void {
    this.val = value;
  }
  registerOnChange(fn: (value:any)=>{}): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disable = isDisabled;
  }

  colocaTexto(){
    this.insertTextInCursor(this.ferramentas.outpuText);
  }
  
  insertTextInCursor(texto?:string){
    if(! this.disable){
    let valueText = this.textarea.nativeElement.value;
    let firtsPart = valueText.substr(0,this.textarea.nativeElement.selectionStart);
    let LastPart = valueText.substr(this.textarea.nativeElement.selectionEnd,valueText.length);
    this.textarea.nativeElement.value = firtsPart + texto + LastPart;
    this.val = firtsPart+texto+LastPart;
    this.textarea.nativeElement.selectionEnd = valueText.length - LastPart.length;
    this.alterou.emit(this.value)
  
    }
  }


  onInput(event){
    if(! this.disable){
    this.val = event.target.value;
    this.alterou.emit(this.value);
    }
  }

}
