import { Component, OnInit, AfterContentInit, AfterViewInit, ViewChild, ElementRef , ContentChild, QueryList, AfterViewChecked, ViewChildren} from '@angular/core';
import {trigger, state, transition, animate, style} from '@angular/animations';
import { PostsService } from '../services/posts.service';
import {CarrocelDirective} from '../diretivas/carrocel.directive';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
    animations: [
        trigger('muda', [
            state('preto',
                style({
                backgroundColor : '#555'
            })),
            state('branco',
                style ({
                    backgroundColor : '#fff'
            })),

                transition('branco <=> preto', [
                    animate('0.4s')
                ]),
        ]),
]

})
export class BlogComponent implements OnInit , AfterViewChecked {
    @ViewChild('carrocel') wrap: ElementRef<any>;
    @ViewChildren('item') iten: QueryList<ElementRef>;
   private qtdCapas: number;
   private capaatual: number;
   private posts: any;
   private postsprontos: any;
   isTrue = true;
   public erro : any;

  constructor(private service: PostsService) { }

  ngOnInit() {
    this.getdados();
    this.capaatual = 0;
    this.postsprontos = [];
    // this.atalizaAutomatico();
  }
  ngAfterViewChecked() {
      //this.playcarrocel();
  }

  getdados() {
    this.service.getpost().subscribe(
        (data) => {
            this.posts = data;
            this.selecionacapas();
        },
        (error) => {
          this.posts = "teste de mensssagem";
        });
  }

  redustexto(texto: string) {
    return texto.substring(0, 200);
  }

  togleCor() {
    this.isTrue = !this.isTrue;
  }

  selecionacapas() {
    for (let i = 0; i < 6; i++) {
      this.postsprontos.push(this.posts[this.capaatual]);
      this.updateCapaAtual();
    }
  }
  updateCapaAtual() {
    if (this.posts.length > this.capaatual) {
        this.capaatual = this.capaatual + 1;
    } else {
      this.capaatual = 0;
    }
  }
  atalizaAutomatico() {
    const interval = setInterval(this.selecionacapas, 5000);
  }

  passaDireita() {
      if (this.wrap.nativeElement.scrollLeft >= this.wrap.nativeElement.offsetWidth) {
          this.wrap.nativeElement.scrollLeft  = 0;
      } else {
          this.wrap.nativeElement.scrollLeft += this.wrap.nativeElement.lastChild.offsetWidth;
      }
  }

  passaEsquerda() {
          this.wrap.nativeElement.scrollLeft -= this.wrap.nativeElement.lastChild.offsetWidth;
  }

  playcarrocel() {
      const interval = setInterval(() => {
          if (this.wrap.nativeElement.scrollLeft >= this.wrap.nativeElement.offsetWidth) {
              this.wrap.nativeElement.scrollLeft  = 0;
          } else {
              this.wrap.nativeElement.scrollLeft += this.wrap.nativeElement.lastChild.offsetWidth;
          }
      }, 5000 );
  }



}
