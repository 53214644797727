import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import {config} from '../assets/config/config.js';

@Injectable({
  providedIn: 'root'
})
export class ContatoService {
  public url = config.url;

  constructor(private http:HttpClient) { }


  enviaEmail(contato:any): Observable<any>{
    return this.http.post(this.url+'/contato',contato,{headers: {"content-type":"application/json"}});
  }
}
