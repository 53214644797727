import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[carrocel]'
})
export class CarrocelDirective {

  constructor( private element: ElementRef ) {

  }

}
