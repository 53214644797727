import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../../../assets/config/config.js';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComentarioService {
  private headers = {headers : new HttpHeaders()};
  private url = config.url + "/blog/comments"
  constructor( private http: HttpClient ) {
  }

  getComments(idPost:string){
     
  }

  deleteComment(idComment:string): Observable<any>{
    return this.http.delete(this.url+"/"+ idComment);
  }

  mudaStatusComment(idPost:string, valor:number): Observable<any>{
    return this.http.patch(this.url+"/"+idPost+"/ativar",{liberado : valor});
  }
}
