import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostagemRoutingModule } from './postagem-routing.module';
import { EditaPostagemComponent } from './edita-postagem/edita-postagem.component';
import { NovaPostagemComponent } from './nova-postagem/nova-postagem.component';
import { PostagemComponent } from './postagem.component';
import { MaterialImportsModule } from '../../material-imports/material-imports.module';
import {DetailPostagemComponent} from './detail-postagem/detail-postagem.component';
import {ReactiveFormsModule} from '@angular/forms';
import { LoadImagemComponent } from './load-imagem/load-imagem.component';
import { TradutorJ2Component } from 'src/app/tradutor-j2/tradutor-j2.component';
import { FerramentasComponent } from 'src/app/ferramentas/ferramentas.component';
import { TextEditorComponent } from 'src/app/text-editor/text-editor.component';


@NgModule({
  declarations: [ 
    EditaPostagemComponent,
     NovaPostagemComponent, 
     PostagemComponent,
      DetailPostagemComponent, 
      LoadImagemComponent,
       TradutorJ2Component,
      FerramentasComponent,
      TextEditorComponent,
     ],
  imports: [
    CommonModule,
    PostagemRoutingModule,
      MaterialImportsModule,
      ReactiveFormsModule
  ]
})
export class PostagemModule { }
