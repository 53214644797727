import { Injectable } from '@angular/core';
import { Observable, ObjectUnsubscribedError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from './../../../../assets/config/config.js';


@Injectable({
  providedIn: 'root'
})
export class ArtigoService {
private headers: HttpHeaders;
private url: string;
  constructor( private http: HttpClient ) {
    this.url = config.url + '/blog';
    this.headers = new HttpHeaders();
    this.headers.append('Accept','Application/json');
    this.headers.append('Content-Type','Application/json');
  }

  getArtigo(id: number): Observable<any> {
    console.log(this.url);
    return this.http.get(this.url + '/posts/' + id);

  }
}

