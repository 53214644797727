import { Component, OnInit } from '@angular/core';
import {NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public menuHiddenIsOpen = false;

  constructor(private router: Router) {

    this.router.events.subscribe(( event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0,0);
      }
    });
  }

  ngOnInit() {

  }

  toogleMenu(){
    this.menuHiddenIsOpen = this.menuHiddenIsOpen ? false : true;
  }
}