import { Component, OnInit,ElementRef, Renderer2, ViewChild, Output,EventEmitter,QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { ImagemserviceService } from 'src/app/imagemservice.service';
import { isUndefined } from 'util';
import { isEmpty } from 'rxjs/operators';

@Component({
  selector: 'load-imagem',
  templateUrl: './load-imagem.component.html',
  styleUrls: ['./load-imagem.component.css']
})
export class LoadImagemComponent implements OnInit , AfterViewInit{
  private preview_image: any;
  private imagens;
  private imagen;
  @ViewChild("container") private container: ElementRef;
  @ViewChildren("imageItem") private allimage: QueryList<ElementRef<any>>;
  public UrlImageSelected:string;
  @Output()insert = new EventEmitter();

  constructor(private service:ImagemserviceService, private render: Renderer2) { }

  ngOnInit() {
    this.getimages();
  }

  ngAfterViewInit(){
  }

  getFiles(event){
    if(event.target.files && event.target.files[0]){

      const reader =  new FileReader();

      reader.readAsDataURL(event.target.files[0]);
      this.imagen = event.target.files[0];

      reader.onload = ()=>{
        this.preview_image = reader.result;
      }
    }
  }

  uploadImage(){
    let form = new FormData();
    form.append('image',this.imagen);
    this.service.uploadimage(form).subscribe((data)=>{
    this.getimages();
    });
  }
  getimages(){
    this.service.getAllImages().subscribe((data)=>{
      this.imagens = data;
    });
  }
  open(){
    this.render.setAttribute(this.container.nativeElement,"style","visibility:visible");
    this.limpaSelecao();
  }

  close(){
    this.render.setAttribute(this.container.nativeElement,"style","visibility:hidden");
    
  }

  selectImage(event){
    this.limpaSelecao();
    this.UrlImageSelected = event.target.src;
    this.render.addClass(event.target,"selected");
  }

  limpaSelecao(){
    this.UrlImageSelected = "";
    this.allimage.forEach((element)=>{
      this.render.removeClass(element.nativeElement,"selected");
  });
  }

  final(){
    this.uploadImage();
    this.insert.emit({url:this.UrlImageSelected});
    this.close();
    
  }


}
