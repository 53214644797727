import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextosSiteService {
public url:string;

  constructor(private http:HttpClient) {
    this.url = "/assets/config/";
   }

   getTexts(language?:string):Observable<any>{
    if(!language ){
      language = "pt-br";
    }
    return this.http.get(this.url + language + ".json")

   }
}
