import { Component, OnInit , Input, } from '@angular/core';
import { CommentsService} from './comments.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  private  postId: number;
  public comments: any;
  public nome: string;
  public email: string;
  public comentario: string;
  public message: any;

  constructor(private service: CommentsService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.getRouteparans();
    this.getcomentspost(this.postId);
  }

  getcomentspost(postId: number) {
    this.service.getcomentspost(this.postId).subscribe(
        (data) => {
            this.comments = data.data;
          },
        () => {
          this.message = 'não foi possivel buscar os comentários do artigo';
        });
  }
  cadastraComment() {
    const comentario = {
        name: this.nome,
        email: this.email,
        body: this.comentario,
        post: this.postId,
        liberado: false
    };
    this.service.insertcomments(comentario).subscribe(
        (data) => {
            this.message = 'Comentario adicionado com sucesso!';
            this.getcomentspost(this.postId);
            },
        (error) => {
            this.message = 'Não foi possivel adicionar o comentario';
            alert(error.erro.message)
        });
  }
    getRouteparans() {
        this.route.params.subscribe((parametro) => {
            this.postId = parametro.id;
        });
    }

}
