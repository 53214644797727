import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AuthGuard } from '../auth/auth.guard';
import {DashboardComponent} from './dashboard/dashboard/dashboard.component';
import {PostagemComponent} from './postagem/postagem.component';
import {EditaPostagemComponent} from './postagem/edita-postagem/edita-postagem.component';
import {NovaPostagemComponent} from './postagem/nova-postagem/nova-postagem.component';
import {DetailPostagemComponent} from './postagem/detail-postagem/detail-postagem.component';
import {ComentarioComponent} from './comentario/comentario.component';

const routes: Routes = [
    { path: 'admin',   redirectTo: '/admin/dashboard', pathMatch: 'full' },
    { path: 'admin', component: AdminComponent, canActivate: [AuthGuard],
        children: [
            { path: 'dashboard', component: DashboardComponent, canActivateChild: [AuthGuard] },
            { path: 'postagem', component: PostagemComponent, canActivateChild: [AuthGuard], children: [
                    {path: 'edita/:id', component: EditaPostagemComponent },
                    {path: 'novo', component: NovaPostagemComponent },
                    {path: 'detail', component: DetailPostagemComponent },
                    {path: 'comentarios/:id', component: ComentarioComponent },
                ]},
        ]
    },
    
    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
