import { Component, OnInit, Input } from '@angular/core';
import { traducoes } from './config-tradutor.js';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'tradutor-j2',
  templateUrl: './tradutor-j2.component.html',
  styleUrls: ['./tradutor-j2.component.css']
})
export class TradutorJ2Component implements OnInit {
private _text:any;

@Input() set text(text:string){
 this._text =  this.sanitizer.bypassSecurityTrustHtml(TradutorJ2Component.asHTML(text));
}
  constructor( private sanitizer: DomSanitizer) { 

    
  }

  ngOnInit() {
  }

  static asHTML(text:string){
    for(let property in traducoes){
      while(text.search(traducoes[property].j2.inicio) > -1 ){
        text = text.replace(traducoes[property].j2.inicio,traducoes[property].html.inicio);
        text = text.replace(traducoes[property].j2.fim,traducoes[property].html.fim);
      }
    };
  return text;
 
  }

  static cleanText(text:string):string{
    let html  = TradutorJ2Component.asHTML(text);
    let result = new DOMParser().parseFromString(html,'text/html');
    return result.body.textContent;
  }
}
