import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatListModule,
    MatSortModule,
} from '@angular/material';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from '@angular/router';
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressSpinnerModule} from "@angular/material";
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatBadgeModule} from '@angular/material/badge';
import { MatFileUploadModule } from 'angular-material-fileupload';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
      BrowserAnimationsModule ,
      MatCardModule ,
      MatButtonModule,
      MatFormFieldModule,
      MatSelectModule,
      MatInputModule,
      MatIconModule,
      FormsModule,
      ReactiveFormsModule,
      RouterModule,
      MatSnackBarModule,
      MatToolbarModule,
      MatSidenavModule,
      MatListModule,
      MatStepperModule,
      MatProgressSpinnerModule,
      MatSortModule,
      MatToolbarModule,
      MatMenuModule,
      MatExpansionModule,
      MatGridListModule,
      MatSlideToggleModule,
      MatBadgeModule,
      MatFileUploadModule,
      MatPaginatorModule,
      MatTooltipModule,


  ],
    exports: [
        CommonModule,
        BrowserAnimationsModule ,
        MatCardModule ,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatSidenavModule,
        MatListModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatToolbarModule,
        MatMenuModule,
        MatExpansionModule,
        MatGridListModule,
        MatSlideToggleModule,
        MatBadgeModule,
        MatFileUploadModule,
        MatPaginatorModule,
        MatTooltipModule,

    ]
})
export class MaterialImportsModule { }
