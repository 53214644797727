import { Component, OnInit, Input } from '@angular/core';
import { Postagem } from 'src/app/admin/postagem/Class/Postagem';
import {TradutorJ2Component} from '../../../tradutor-j2/tradutor-j2.component';

@Component({
  selector: 'app-capa-artigo',
  templateUrl: './capa-artigo.component.html',
  styleUrls: ['./capa-artigo.component.css']
})
export class CapaArtigoComponent implements OnInit  {
   @Input()postagem: Postagem;
   @Input ()introlength: number;
  constructor() { }

  ngOnInit() {
  }

  reduzText(tamanho?:number){
    const legthdefalt = 300;
    if(!this.introlength){
    this.introlength = legthdefalt;
    }
    let text = TradutorJ2Component.cleanText(this.postagem.text);
    return text.substring(0,this.introlength);
  }

}
