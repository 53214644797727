export class Postagem {
    public image: any;

    public title: string;
   
    public text: string;
    
    public datapost: string;
   
    public ativado: boolean;

    public author:number;
   

    constructor( image?: any, title?: string, text?: string, dataPost?: string ,ativado?: boolean , author?:number ) {
        this.image = image;
        this.title = title;
        this.text = text;
        this.datapost = dataPost;
        this.ativado = ativado;
        this.author = author;
    }
/*
    public get image(){
        return this._image;
    }

    public set image(image:any){
        this._image = image;
    }

    public get title(): string {
        return this._title;
    }
    public set title(value: string) {
        this._title = value;
    }

    public get text(): string {
        return this._text;
    }
    public set text(value: string) {
        this._text = value;
    }
    public get ativado(): boolean {
        return this._ativado;
    }
    public set ativado(value: boolean) {
        this._ativado = value;
    }

    public get dataPost(): string {
        return this._dataPost;
    }
    public set dataPost(value: string) {
        this._dataPost = value;
    }
*/
   }
