import { Component, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AuthGuard} from '../auth.guard';
import {AuthService} from '../auth.service';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {

public formLogin = new FormGroup({
    usuario: new FormControl('', [Validators.required, Validators.email ]),
    senha: new FormControl('', [Validators.required]),
});

  constructor( private router: Router,
               private snackBar: MatSnackBar,
               private auth: AuthService
               ) { }

  ngOnInit() {

  }

  OnSubmit() {
  if (this.formLogin.valid) {
      this.auth.login().subscribe(() => {
          if (this.auth.isLoggedIn) {
             const redirect = this.auth.redirectUrl ? this.router.parseUrl(this.auth.redirectUrl) : '/admin/dashboard';
             this.router.navigateByUrl(redirect);
          }
      });
    }
  }

  sair() {
      this.auth.logout();
      this.router.navigate(['/login/dashboard']);
  }

    getErrorMessage(controls: FormControl) {
        console.log(controls.errors);
        return controls.hasError('required') ? 'campo é necessário' :
            controls.hasError('email') ? 'Isso não é um email' :
                controls.hasError('minlength') ? 'O campo deve conter pelo menos caracteres' : '';

    }
}
