import { Component, OnInit, Input } from '@angular/core';
import { TextosSiteService } from '../services/textos-site.service';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.css']
})
export class SobreComponent implements OnInit {
  @Input() language:string;
  public textoSite;

  constructor(private service:TextosSiteService) { }


  ngOnInit() {

    this.getText()
  }

  getText(){
    if(!this.language || this.language){
      this.language = "pt-br"
    }

    this.service.getTexts(this.language).subscribe(
      (data) => {
        this.textoSite = data;
      },
      (error)=>{
        this.textoSite = error;
      });

  }

}
