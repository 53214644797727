import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, empty } from 'rxjs';
import { config } from '../../../assets/config/config.js';


@Injectable({
  providedIn: 'root'
})
export class PostagemService {

  private headers = {headers : new HttpHeaders()};
  private url;
  constructor( private http: HttpClient ) {
    this.url = config.url + '/blog';
    this.headers.headers.append('enctype', 'multipart/form-data');
    this.headers.headers.append('Accept' , 'application/json');
  }
  getPostagens(filtros?:any): Observable<any> {
    let query = "";
    if(filtros !== null){
      query = this.crateQuery(filtros);
    }
    return this.http.get(this.url + '/posts' + query);
  }
  deletePostagen(idPost: number): Observable<any> {
    return this.http.delete(this.url + '/posts/' + idPost);
  }
  changeAtivado(idpost: number , valor:number): Observable<any> {
    return this.http.patch(this.url + '/posts/' + idpost + '/muda-status', { ativado : valor});
  }
  updatePost(idPost, post:any): Observable<any> {
    return this.http.post(this.url + '/posts/' + idPost, post);
  }
  getPostBYId(id: any): Observable<any> {
    return this.http.get(this.url +'/posts/' + id);
  }
  comments(idPost:string):Observable<any>{
    return this.http.get(this.url +'/posts/' + idPost + '/comments');
  }
  cratePost(post: any):Observable<any>{
    return this.http.post(this.url +'/posts',post,this.headers);
  }

  crateQuery( filtros:any ):string{
    let query= "";
    if(filtros !== null){
      query = "?";
      for(let chave in filtros){
        if(filtros[chave] !== null){
          if(query !== "?"){
            query = query + "&"
          }
          query= query + chave +"="+filtros[chave];
        }
      }
    }
    return query;
  }
}
