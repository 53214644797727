import { Component, OnInit, Sanitizer } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ArtigoService} from './artigo.service';
import {DomSanitizer, SafeHtml, Meta , Title, MetaDefinition} from '@angular/platform-browser';
import { TradutorJ2Component } from '../../../tradutor-j2/tradutor-j2.component';


@Component({
  selector: 'app-artigo',
  templateUrl: './artigo.component.html',
  styleUrls: ['./artigo.component.css','./texto.css'],
 
})
export class ArtigoComponent implements OnInit {
  private routeParans: number;
  private artigo: any;
  private text:SafeHtml;
  private PageMeta : Meta;

  constructor(private route: ActivatedRoute, private service: ArtigoService ,private sanitizer:DomSanitizer,
    private nav:Router , private titleService:Title , private MetaServise: Meta) {
   
  }
 

  ngOnInit() {
    this.getRouteparans();
    this.getArtigo();
  }

  getArtigo() {
     this.service.getArtigo(this.routeParans).subscribe(
         (data) => {
           this.artigo = data;
           this.artigo.text = this.sanitizer.bypassSecurityTrustHtml(TradutorJ2Component.asHTML(data.text));
           this.artigo.image =  data.image;
           this.titleService.setTitle(data.title);
           this.MetaServise.addTag({name: "keyeords" , content: "Direito, lucas miranda, advocacia, Advogado Ibirité ,Advocacia Ibirité"}, true);
           this.MetaServise.addTag({name: "Author" , content: "Lucas Miranda"}, true);
         },
         (error) => {
         }
     );
  }
  getRouteparans() {
        this.route.params.subscribe((parametro) => {
            this.routeParans = parametro.id;
        });
    }

  voltar(){
    this.nav.navigate(['site/blog/posts']);
  }

   
}
