import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PageListaArtigosComponent} from './page-lista-artigos/page-lista-artigos.component';
import {ArtigoComponent} from './artigo/artigo.component';
import { BlogComponent } from './blog/blog.component';

const blogRoutes: Routes = [
    { path: 'blog', component: BlogComponent,
      children: [
          { path: 'posts', component: PageListaArtigosComponent },
          { path: 'post/:id', component: ArtigoComponent },
      ]
    },
];

@NgModule({
  imports: [RouterModule.forChild(blogRoutes)],
  exports: [RouterModule]
})
export class BlogRoutingRoutingModule { }
