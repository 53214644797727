import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {EditaPostagemComponent} from './edita-postagem/edita-postagem.component';
import {NovaPostagemComponent} from './nova-postagem/nova-postagem.component';
import {PostagemComponent} from './postagem.component';


const Postagemroutes: Routes = [
    {path: 'postagem', component: PostagemComponent, children: [
            { path: 'edita' , component: EditaPostagemComponent },
            { path: 'nova', component: NovaPostagemComponent },
        ]}
];

@NgModule({
  imports: [RouterModule.forChild(Postagemroutes)],
  exports: [RouterModule]
})
export class PostagemRoutingModule { }
