import {Component, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {MatSidenav} from '@angular/material';
import {Router} from '@angular/router';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit , AfterViewInit {

  @ViewChild('sidenav') sideNave: MatSidenav;

  constructor(private router: Router ) { }

  ngOnInit() {
   
  }
  ngAfterViewInit() {
  
  }

  toggleMenu() {
    this.sideNave.toggle();
  }
  dashboard() {
    this.router.navigate(['/admin/dashboard']);
    this.toggleMenu();
  }
  postagens() {
        this.router.navigate(['/admin/postagem']);
        this.toggleMenu();
    }
    comments() {
        this.router.navigate(['/admin/comenetario']);
        this.toggleMenu();
    }
    usuarios() {
        this.router.navigate(['/admin/comenetario']);
        this.toggleMenu();
    }

}
