import { Component, OnInit, ViewChild, ElementRef,Renderer2 } from '@angular/core';
import{ FormGroup, FormControl} from '@angular/forms'
import { Postagem } from '../Class/Postagem';
import { PostagemService } from '../postagem.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nova-postagem',
  templateUrl: './nova-postagem.component.html',
  styleUrls: ['./nova-postagem.component.css']
})
export class NovaPostagemComponent implements OnInit {
  @ViewChild("textarea") textArea: ElementRef;
  public texto;
   formdata = new FormData();
   imagem:any;
   text_preview?:string;
  public postForm = new FormGroup ({
    image: new FormControl(),
    title: new FormControl(),
    text: new FormControl(''),
  });

  constructor(private service: PostagemService, private snack: MatSnackBar, private route:Router,private render:Renderer2) { }

  ngOnInit() {
  }

  newPost(){
    const date =  new Date();
    if(this.postForm.valid){ 
      const post = new Postagem();
      post.title = <string>this.postForm.get('title').value;
      post.text = <string>this.postForm.get('text').value;
      post.ativado = false ;
      post.datapost = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();
      post.author = 1;

     this.formdata.append('text',post.text);
     this.formdata.append('title',post.title);
     this.formdata.append('image',post.image);
     this.formdata.append('ativado',post.ativado.toString());
     this.formdata.append('datapost',post.datapost);
     this.formdata.append('autor',post.author.toString());
     
      this.service.cratePost(this.formdata).subscribe(
        (data)=>{
          this.snack.open(data.title + " Cadastrado !", "Fechar");
          this.route.navigate(['admin/postagem']);
        },
        (erro)=>{
          this.snack.open( "erro ao cadastrar !" + erro.error.message, "Fechar");
        });
    }
  }
  inputFileChange(event){
    let imagem:any;
    if(event.target.files && event.target.files[0]){
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=>{
        this.imagem = reader.result
      }
      this.formdata.append('image', event.target.files[0]);
      this.imagem = event.target.value;
    }
  }

  preview(event){
    this.text_preview = event;
  }

}
