import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './auth/login/login.component';
import { PageHomeComponent } from './site/page-home/page/page-home.component';


const appRoutes: Routes = [
{path:'', redirectTo: 'site/home' , pathMatch:'full'}
];

@NgModule({
  declarations: [
  ],
  imports: [
      RouterModule.forRoot(
          appRoutes
      ),
    CommonModule,
  ],

    exports: [
    RouterModule
    ]
})
export class AppRoutingModule { }
