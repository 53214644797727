import { Component, OnInit, Input } from '@angular/core';
import { TextosSiteService} from '../../services/textos-site.service'

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {
  @Input() language: string;
  public textosSobre:any;
  public topicos:any;

  constructor( private service: TextosSiteService) { }

  ngOnInit() {
    this.getTextos();
  }

  getTextos(){
    this.service.getTexts().subscribe(
    (data)=>{
      this.textosSobre = data.empresa;     
    });
  }
}
