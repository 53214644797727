import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { LoginComponent} from '../auth/login/login.component';
import { MaterialImportsModule } from '../material-imports/material-imports.module';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import {PostagemModule} from './postagem/postagem.module';
import { ComentarioComponent } from './comentario/comentario.component';
import { TradutorJ2Component } from '../tradutor-j2/tradutor-j2.component';

@NgModule({
  declarations: [
      AdminComponent,
      LoginComponent,
      DashboardComponent,
      ComentarioComponent,
  ],
  imports: [
    CommonModule,
      MaterialImportsModule,
      PostagemModule,
      AdminRoutingModule,
  ]
})
export class AdminModule { }
