import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PageServicoComponent} from './page-servicos/page-servico/page-servico.component';
import {PageComponent} from './page-quem-somos/page/page.component';
import {PageHomeComponent} from './page-home/page/page-home.component';
import {SiteComponent} from './site.component';
import { ArtigoComponent } from './page-blog/artigo/artigo.component';
import { PageListaArtigosComponent } from './page-blog/page-lista-artigos/page-lista-artigos.component';
import { BlogComponent } from './page-blog/blog/blog.component';

const routes: Routes = [
    { path: 'site', component: SiteComponent,
        children : [
            { path: 'blog', component: BlogComponent,
                 children: [
                  { path: 'posts', component: PageListaArtigosComponent },
                    { path: 'post/:id', component: ArtigoComponent },
        ]
    },
            { path: 'home', component: PageHomeComponent },
            { path: 'quem-somos', component: PageComponent },
            { path: 'servicos', component: PageServicoComponent },
            { path: 'servicos/:servico', component: PageServicoComponent },
        ]
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SiteRoutingModule { }
