import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import { PostagemService } from '../postagem.service';
import {Router, ParamMap, ActivatedRoute} from '@angular/router';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';
import { Postagem } from '../Class/Postagem';



@Component({
  selector: 'app-edita-postagem',
  templateUrl: './edita-postagem.component.html',
  styleUrls: ['./edita-postagem.component.css']
})
export class EditaPostagemComponent implements OnInit {
    public post;
    text_preview?: string;
    private snackConf;
    private imagem:any;
    private formData:FormData;
    formpost =  new FormGroup({
      image: new FormControl(''),
      title: new FormControl(''),
      text: new FormControl(''),
    });

  constructor(private service: PostagemService, private router: Router, private route: ActivatedRoute, private snack: MatSnackBar) {
      this.snackConf = new MatSnackBarConfig();
      this.snackConf.duration = 5000;
      this.snackConf.horizontalPosition = 'end';
      this.snackConf.verticalPosition = 'bottom';
      this.formData =  new FormData();
    
  }

  ngOnInit() {
      this.post = this.route.paramMap.subscribe(
          (data: ParamMap) => {
            this.getPost(data.get('id'));
          });
  }

  editaPost() {
    if(this.formpost.valid) {
      this.formData.append("title", this.formpost.get('title').value);
      this.formData.append("text",this.formpost.get('text').value);
      this.formData.append("_method","PUT")
      this.service.updatePost( this.post.id , this.formData ).subscribe(
          (data) => {
            this.snack.open('post '+ data.title+' alterado', 'Fechar', this.snackConf);
          },
          (error) => {
              this.snack.open('não foi possivel alterar'+ error.error.message, 'Fechar', this.snackConf);
          }
      );
    }
    this.formData.delete("text");
    this.formData.delete("title");
    this.formData.delete("image");
  }
  getPost(id: any) {
      this.service.getPostBYId(id).subscribe(
          (post) => {
            this.post = post;
            this.formpost.get('title').setValue(post.title);
            this.formpost.get('text').setValue(post.text);
            this.imagem = post.image;
            this.text_preview = post.text;
          });
  }

  inputFileChange(event){
    if(event.target.files && event.target.files[0]){
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = ()=>{
        this.imagem = reader.result
      }
      this.formData.append('image', event.target.files[0]);
      this.imagem = event.target.value;
    }
  }

  mudou(event){
    this.text_preview = event;
  }



}
