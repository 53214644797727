import { BrowserModule } from '@angular/platform-browser';
import { NgModule,  } from '@angular/core';


import { AppComponent } from './app.component';
import { AppRoutingModule} from './app-routing.module';
import {AdminModule} from './admin/admin.module';
import {SiteModule} from './site/site.module';
import {SiteComponent} from './site/site.component';
import {Router} from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PesquisaComponent } from './pageblog/pesquisa/pesquisa.component';



@NgModule({
  declarations: [
      AppComponent,
      SiteComponent,
      PesquisaComponent,
  ],
  imports: [
    BrowserModule,
      AdminModule,
      SiteModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor(router: Router) {
        // Use a custom replacer to display function names in the route configs
        const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

        console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
    }
}
