import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-pesquisa',
  templateUrl: './pesquisa.component.html',
  styleUrls: ['./pesquisa.component.css']
})
export class PesquisaComponent implements OnInit {

  campo = new FormControl()
  @Output() pesquisa = new EventEmitter();

  constructor( private router:Router) { }

  ngOnInit() {
  }

  pesquisar(){
    this.pesquisa.emit(null);
  }

  

}
