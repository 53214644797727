import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
    @Input() rotulos: string;
    links: string[] ;
  constructor() { }

  ngOnInit() {
      this.separaRotulos();
  }

  separaRotulos() {
      this.links = this.rotulos.split(',');
  }
}
