import {Component, OnInit} from '@angular/core';
import {PostagemService} from './postagem.service';
import {Postagem} from './Class/Postagem';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material';
import {Navigation, Router} from '@angular/router';

@Component({
    selector: 'app-postagem',
    templateUrl: './postagem.component.html',
    styleUrls: ['./postagem.component.css']
})
export class PostagemComponent implements OnInit {

    public postagens: Postagem[];
    private snackConf: MatSnackBarConfig;
    public isLoading = false;

    constructor(private service: PostagemService, private snack: MatSnackBar, private router: Router) {
        this.snackConf = new MatSnackBarConfig();
        this.snackConf.duration = 5000;
        this.snackConf.horizontalPosition = 'end';
        this.snackConf.verticalPosition = 'bottom';
    }

    ngOnInit() {
        this.listaPostagens();
    }

    toggleSpinner() {
        return this.isLoading ? false : true;
    }

    listaPostagens() {
        this.toggleSpinner();
        this.service.getPostagens().subscribe(
            (postagens) => {
                this.postagens = postagens.data;
            }, (error) => {
                this.snack.open(error.message, 'fechar', this.snackConf);

            });
    }

    deletePost(idPost: number) {
        this.service.deletePostagen(idPost).subscribe(
            (post) => {
                this.listaPostagens();
                this.snack.open(post.title + ' Deletado!', 'fechar', this.snackConf);
            }, (error) => {
                let menssagem: string;
                if (error.status === 500) {
                    menssagem = ' Não foi possivel deletar o artigo entre em contato com o administrador | codigo: ' + error.status;
                } else if (error.status === 404) {
                    menssagem = ' Não foi possivel deletar o artigo pois ele não foi encontrado | codigo:' + error.status;
                } else {
                    menssagem = ' Não foi possivel deletar o artigo entre em contato com o administrador com codigo:' + error.status;
                }
                this.snack.open(menssagem, 'fechar', this.snackConf);
            });
    }

    editePost(idpost) {
        this.router.navigate(['admin/postagem/edita/', idpost]);
    }
    comments(idpost) {
        this.router.navigate(['admin/postagem/comentarios/', idpost]);
    }

    convertBoolean(num) {
        return num === 0 ? false : true;
    }

    mudaStatus(id: number, valor: number) {
        const novoValor = valor === 1 ? 0 : 1;
        this.service.changeAtivado(id, novoValor).subscribe(
            (data) => {
               const mensagem = data.ativado === 1 ? 'ativada' : 'desativada';
               this.snack.open('postagem ' + mensagem + '!', 'Fechar', this.snackConf);
               this.listaPostagens();
            });
    }

    novoPost(){
        this.router.navigate(['admin/postagem/novo']);
    }

}
