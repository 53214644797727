 export const traducoes = {
     "paragrafo": {
         "html": {
             "inicio": "<p class='paragrafo-blog'>",
             "fim": "</p>"
         },
         "j2": {
             "inicio": "#paragrafo#",
             "fim": "#fim-paragrafo#"
         }
     },

     "citacao": {
         "html": {
             "inicio": "<p class='citacao-blog'><span class='aspas'>\"</span>",
             "fim": "<span class='aspas'>\"</span></p>"
         },
         "j2": {
             "inicio": "#citacao#",
             "fim": "#fim-citacao#"
         }
     },

     "fonte": {
         "html": {
             "inicio": "<span class='fonte-citacao'>(",
             "fim": ")</span>"
         },
         "j2": {
             "inicio": "#fonte-citacao#",
             "fim": "#fim-fonte-citacao#"
         }
     },

     "titulo": {
         "html": {
             "inicio": "<h1 class='titulo-blog'>",
             "fim": "</h1>"
         },
         "j2": {
             "inicio": "#titulo#",
             "fim": "#fim-titulo#"
         }
     },

     "sub-titulo": {
         "html": {
             "inicio": "<h1 class='sub-titulo-blog'>",
             "fim": "</h1>"
         },
         "j2": {
             "inicio": "#sub-titulo#",
             "fim": "#fim-sub-titulo#"
         }
     },
     "imagem": {
         "html": {
             "inicio": "<img src='",
             "fim": "' class='imagem-texto-blog'/>"
         },
         "j2": {
             "inicio": "#imagem#",
             "fim": "#fim-imagem#"
         }
     },
     "legenda-imagem": {
         "html": {
             "inicio": "<p class='legenda-imagem-blog'>",
             "fim": "</h1>"
         },
         "j2": {
             "inicio": "#legenda-imagem#",
             "fim": "#fim-legenda-imagem#"
         }
     },

     "link": {
         "html": {
             "inicio": "<a",
             "fim": ""
         },
         "j2": {
             "inicio": "#link#",
             "fim": "#fim-link#"
         }
     },
     "endereco-link": {
         "html": {
             "inicio": "href='",
             "fim": "'</a>"
         },
         "j2": {
             "inicio": "#endereco-link#",
             "fim": "#fim-endereco-link#"
         }
     }
 }