import { Component, OnInit, ViewChild } from '@angular/core';
import { PostagemService } from 'src/app/admin/postagem/postagem.service';
import { PesquisaComponent } from '../pesquisa/pesquisa.component';
import { MatPaginator, PageEvent,MatPaginatorIntl } from '@angular/material';

@Component({
  selector: 'app-page-lista-artigos',
  templateUrl: './page-lista-artigos.component.html',
  styleUrls: ['./page-lista-artigos.component.css']
})
export class PageListaArtigosComponent implements OnInit {
 public posts;
 @ViewChild(PesquisaComponent) campo : PesquisaComponent;
 @ViewChild(MatPaginator) paginatior:MatPaginator;
 PageEvent:PageEvent;
  constructor( private service: PostagemService) { }

  ngOnInit() {
    this.getPosts();
  }

  getPosts(filtros?:any){
    this.service.getPostagens({ativado:1}).subscribe(
      (data)=>{
        data.data.forEach(element => {
          element.image = element.image;
        });
        this.posts = data.data;
        this.paginatior.pageSize = data.per_page;
        this.paginatior.length = data.total;
      });
  }
  pesquisaPosts(){
    this.service.getPostagens({ativado:1,pesquisa:this.campo.campo.value}).subscribe(
      (data)=>{
        
        this.posts = data.data;
        this.paginatior.pageSize = data.per_page;
        this.paginatior.length = data.total;
      });
  }  

  changePagination(event){
    this.service.getPostagens({ativado:1,pesquisa:this.campo.campo.value,page:event.pageIndex + 1}).subscribe(
      (data)=>{
        this.posts = data.data;
      });
  }
}
