import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BlogRoutingRoutingModule } from './blog-routing-routing.module';
import {ArtigoComponent} from './artigo/artigo.component';
import {PageListaArtigosComponent} from './page-lista-artigos/page-lista-artigos.component';
import { DatasComponent } from './datas/datas.component';
import {CategoriasComponent} from './categorias/categorias.component';
import {CapaArtigoComponent} from './capa-artigo/capa-artigo.component';
import { BlogComponent } from './blog/blog.component';
import {AutorComponent} from './autor/autor.component';
import { MatCardModule, MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';



import {CommentsComponent} from './comments/comments.component';
import { PesquisaComponent } from './pesquisa/pesquisa.component';
import { MaterialImportsModule } from 'src/app/material-imports/material-imports.module';

@NgModule({
  declarations: [
      BlogComponent,
      PageListaArtigosComponent,
      ArtigoComponent,
      DatasComponent,
      CategoriasComponent,
      CapaArtigoComponent,
      AutorComponent,
      CommentsComponent,
      PesquisaComponent
  ],
  imports: [
    CommonModule,
      MatCardModule,
      MatInputModule,
      MatButtonModule,
      FormsModule,
      BlogRoutingRoutingModule,
      MaterialImportsModule,

  ]
})
export class BlogRoutingModule { }
