import { Component, OnInit, ViewChild,EventEmitter, Output } from '@angular/core';
import { LoadImagemComponent } from '../admin/postagem/load-imagem/load-imagem.component';

@Component({
  selector: 'ferramentas',
  templateUrl: './ferramentas.component.html',
  styleUrls: ['./ferramentas.component.css']
})
export class FerramentasComponent implements OnInit {

  @ViewChild(LoadImagemComponent) private load_image:LoadImagemComponent;
  public outpuText:string = "";
  @Output() buttonAction = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  paragrafo(){
    this.outpuText = "#paragrafo#\n\tColoque aqui seu paragrafo\n#fim-paragrafo#\n";
    this.action();
  }

  titulo(){
    this.outpuText = "#titulo#\n\tColoque aqui seu Titulo\n#fim-titulo#\n";
    this.action();
  }
  citacao()
  {
    this.outpuText = '#citacao#\n\tColoque aqui o texto q ser citado \n #fim-citacao# #fonte-citacao#\n\tColoque aqui a fonte da citacão \n#fim-fonte-citacao#\n';
    this.action();
  }
  image(event){
    this.outpuText = "\n#imagem#\n\t"+event.url+"\n#fim-imagem# #legenda-imagem#\n\t coloque a legenda aqui\n#fim-legenda-imagem#\n";
    this.action();
  }

  link(){
    this.outpuText = "#link#\n\t \n#endereco-link#\n\tColoque o endereco do link aqui\n#fim-endereco-link#\n Coloque as palavras do link aqui \n#fim-link#\n"
    this.action();
    }
  openImagem(){
    this.load_image.open();
  }

  action(){
    this.buttonAction.emit(null);
    this.outpuText = "";
  }

}
