import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { ContatoService } from 'src/app/contato.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

  @ViewChild('botao') public botao :ElementRef<HTMLButtonElement>

  public contactForm = new FormGroup({
    nome : new FormControl(null, [Validators.required,]),
    email : new FormControl('',[Validators.required]),
    fixo : new FormControl('',),
    cel: new FormControl('',[Validators.required]),
    mensagem: new FormControl('',[Validators.required])
  });
  constructor(private service:ContatoService, private render :Renderer2 , private snak: MatSnackBar) { }

  ngOnInit() {
  }

  mudaclasse(element,rotulo,classenova,classevelha){
    this.render.addClass(element,classenova);
    this.botao.nativeElement.value = rotulo;
    this.render.removeClass(element,classevelha);
  }

  onSubmit(){
    
    this.mudaclasse(this.botao.nativeElement,"enviando...","enviando","enviar");
   if(this.contactForm.valid){
      this.service.enviaEmail(this.contactForm.value).subscribe(
      (data)=>{
        this.mudaclasse(this.botao.nativeElement,"Enviado","enviado","enviando");
        this.snak.open("menssagem enviada com sucesso! retornaremos o mais rápido possivel","Fechar",{
          duration:5000
        });
      }
      ,(error)=>{
        this.snak.open("Não foi possivél enviar sua menssagem , por favor entre em contao pelo whatsapp","Fechar",{
          duration:5000
        });
        this.mudaclasse(this.botao.nativeElement,"Enviar","enviar","enviando");
       },()=>{
        this.contactForm.reset();
      }
      );
    }
  }
}
