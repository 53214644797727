import { Component, OnInit } from '@angular/core';
import { TextosSiteService } from '../../services/textos-site.service';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.css']
})
export class PageHomeComponent implements OnInit {

  public textosSite;
  constructor(private textos: TextosSiteService, private router:Router) {

   }

  ngOnInit() {
    this.getTextos("pt-br");
  }

  getTextos(language:string){
    this.textos.getTexts(language).subscribe(
      (data) => {
        this.textosSite = data;
      },
      (error)=>{
        this.textosSite = error;
      });
    }

    goToAdvocacia(){
      this.router.navigate(['site/servicos','advocacia']);
    }

    goToAssessoria(){
      this.router.navigate(['site/servicos','assesoria']);
    }

    goToConsultoria(){
      this.router.navigate(['site/servicos','consultoria']);
    }

    goToContact(){
      window.scrollTo(0, 2150);
    }
}
