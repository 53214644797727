import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  public caminho: any
  isMan: boolean;
  constructor() {

    this.isMan = false;
  }

  ngOnInit() {
  }

}
