import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';

import { MenuComponent } from './menu/menu.component';
import { SobreComponent } from './sobre/sobre.component';
import { BlogComponent } from './blog/blog.component';
import { ContatoComponent } from './contato/contato.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageComponent } from './page-quem-somos/page/page.component';
import { PageHomeComponent } from './page-home/page/page-home.component';
import { QuadroComponent } from './page-quem-somos/quadro/quadro.component';
import { PageServicoComponent } from './page-servicos/page-servico/page-servico.component';
import { SiteRoutingModule } from './site-routing.module';
import { BlogRoutingModule } from './page-blog/blog-routing.module';
import { CarrocelDirective } from './diretivas/carrocel.directive';
import { HeaderComponent } from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {MaterialImportsModule} from '../material-imports/material-imports.module';
import { PesquisaComponent } from './page-blog/pesquisa/pesquisa.component';


@NgModule({
    declarations: [
        HeaderComponent,
        MenuComponent,
        SobreComponent,
        BlogComponent,
        ContatoComponent,
        PageComponent,
        PageHomeComponent,
        QuadroComponent,
        PageServicoComponent,
        CarrocelDirective,
        FooterComponent,
       
    

    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        BlogRoutingModule,
        SiteRoutingModule,
        MaterialImportsModule,
        
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
    ],
})
export class SiteModule { }
