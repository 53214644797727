import { Component, OnInit } from '@angular/core';
import { TextosSiteService } from "../../services/textos-site.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-servico',
  templateUrl: './page-servico.component.html',
  styleUrls: ['./page-servico.component.css']
})
export class PageServicoComponent implements OnInit {
  private textos:any ;
  private servico:any;

  constructor(private service:TextosSiteService, private routeActive: ActivatedRoute ) { }

  ngOnInit() {
    this.routeActive.params.subscribe(
      (parans)=>{
        this.gettextos(parans.servico);
      });
  }

  gettextos(servico:string){
    this.service.getTexts("pt-br").subscribe(
      (data)=>{
        this.textos = data.servicos;
        this.getServico(servico);
    });
  }

  getServico(nome:string){
    switch(nome){
    case 'advocacia':
       this.servico = this.textos.Advocacia;
    break;
    case 'assesoria':
      this.servico = this.textos.Assessoria;
    break;
    case 'consultoria':
      this.servico = this.textos.Consultoria;
    break;
    default:
      this.servico = this.textos.Advocacia;
    break;
  }
  }

}
