import { Component, OnInit } from '@angular/core';
import {ParamMap , Router , ActivatedRoute } from '@angular/router';
import { PostagemService } from '../postagem/postagem.service';
import { ComentarioService } from './comentario.service';


@Component({
  selector: 'app-comentario',
  templateUrl: './comentario.component.html',
  styleUrls: ['./comentario.component.css']
})
export class ComentarioComponent implements OnInit {
  public comments;
  public idPost;
  constructor(private route:ActivatedRoute , private Postservice:PostagemService , private Commentservice:ComentarioService) { }

  ngOnInit() {
     this.route.paramMap.subscribe (
      (params: ParamMap) =>{
        this.idPost = params.get('id');
          this.getComments(params.get('id'));
      });
  }

  getComments(idPost:string){
    this.Postservice.comments(idPost).subscribe(
      (data) =>{
        this.comments = data.data;
      }
    );
  }

  deleteComments(idcomment:string){
    this.Commentservice.deleteComment(idcomment).subscribe(
      (data)=>{
        alert("comentario deletado!")
        this.getComments(this.idPost);
      },
      (error)=>{
        alert(error.message);
      }
    );
  }

  convertBoolean(valor){
    return valor === 1? true : false;
  }

  changeStatus(idComment:string,valor:number){
    let liberado = valor === 1? 0:1;
    this.Commentservice.mudaStatusComment(idComment,liberado).subscribe(
     (data)=>{
      this.getComments(this.idPost);
     },
     (error)=>{
      alert(error.message);
     }
    );
  }
}
